import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Index.vue') },
      
    ]
  },

  {
    path: '/projects/:project_id',
    component: () => import('layouts/ProjectLayout.vue'),
    children: [
      {path : '', component:()=>import("pages/Dashboard_RiskMatrix.vue")},
      { path: 'analysis', component: () => import('pages/Analysis.vue') },
      // todo: move analysis/view onto a new layout.
      
      { path: 'requirements', component: () => import('pages/Requirements.vue') },
      { path: 'requirements/add', component: () => import('pages/utilities/AddRequirements.vue') },
      { path: 'requirements/edit', component: () => import('pages/utilities/ModifyRequirements.vue') },
      { path: 'providers', component: () => import('pages/Providers.vue') },
      { path: 'providers/edit', component: () => import('pages/utilities/AddProviders.vue') },
      { path: 'tasking', component: () => import('pages/Tasking.vue') },
      { path: 'tasking/edit', component: () => import('pages/utilities/AddTasks.vue') },
    ]
  },

  {
    path: '/projects/:project_id/analysis/view',
    component: () => import('layouts/AnalysisLayout.vue'),
    children: [
      { path: '', component: () => import('pages/utilities/ViewAnalysis.vue') },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
];

export default routes;
