// import something here
import Vue from 'vue';
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css' // needs css-loader
import { CRS, latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LWMSTileLayer, LControlLayers, LRectangle, LPolygon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

// "async" is optional;
// more info on params: https://quasar.dev/quasar-cli/cli-documentation/boot-files#Anatomy-of-a-boot-file
export default async (/* { app, router, Vue ... } */) => {
  // TODO: Remove VueLayers
  Vue.use(VueLayers);

  
  Vue.component("l-map", LMap);
  Vue.component("l-tile-layer", LTileLayer);
  Vue.component("l-marker", LMarker);
  Vue.component("l-wms-tile-layer", LWMSTileLayer);
  Vue.component("l-control-layers", LControlLayers);
  Vue.component("l-rectangle", LRectangle);
  Vue.component("l-polygon", LPolygon);
}
